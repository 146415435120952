import React, { createContext, useEffect, useState } from 'react'
import { logEvent, LogLevel } from '@praxis/component-logging'
import Firebase from 'firebase/compat/app'
import 'firebase/compat/remote-config'
import { useMPMEnv } from 'utils/env'

type FeatureFlags = Record<string, boolean>

const FlagsContext = createContext<FeatureFlags>({})

type FlagsType = {
  children: React.ReactNode
}

export const FlagsProvider = ({ children }: FlagsType) => {
  const { firebase: firebaseConfig } = useMPMEnv()
  const [flags, setFlags] = useState<FeatureFlags>({})

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const firebase = Firebase.initializeApp(firebaseConfig)

        const remoteConfig = firebase.remoteConfig()
        remoteConfig.settings = {
          minimumFetchIntervalMillis: 5000,
          fetchTimeoutMillis: 5000,
        }
        await remoteConfig.fetchAndActivate()

        const newFlags: FeatureFlags = {}
        for (const [key, config] of Object.entries(remoteConfig.getAll())) {
          newFlags[key] = config.asBoolean()
        }
        setFlags(newFlags)
      } catch (error) {
        logEvent(
          { message: 'Error instantiating Firebase Remote Config' },
          { level: LogLevel.Error },
        )
      }
    }

    fetchConfig()
  }, [firebaseConfig])

  return <FlagsContext.Provider value={flags}>{children}</FlagsContext.Provider>
}

export const useFlags = () => {
  const context = React.useContext(FlagsContext)

  return context
}

export default FlagsProvider
