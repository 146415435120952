import { createContext, useState, useContext } from 'react'

export interface Location {
  locationId: string
  shortName: string
  name: string
}

export interface IUserContext {
  location: Location
  setLocation: (location: Location) => void
  greenfieldGroups: string[]
  setGreenfieldGroups: (greenfieldGroups: string[]) => void
  language: string
  setLanguage: (location: string) => void
}

export const UserContext = createContext<IUserContext | undefined>(undefined)

interface IUserProviderProps {
  location?: string
  children: JSX.Element | JSX.Element[] | string
}

export const UserProvider = ({ children }: IUserProviderProps) => {
  const [location, setLocation] = useState<Location>({
    locationId: '',
    shortName: '',
    name: '',
  })

  const [greenfieldGroups, setGreenfieldGroups] = useState<string[]>([])
  const [language, setLanguage] = useState('en')

  const userProviderContextValue = {
    location,
    setLocation,
    greenfieldGroups,
    setGreenfieldGroups,
    language,
    setLanguage,
  }

  return (
    <UserContext.Provider value={userProviderContextValue}>
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => {
  const userContext = useContext(UserContext)

  if (userContext === undefined) {
    throw new Error('useUser must be used within a UserProvider')
  }

  return userContext
}
