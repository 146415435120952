import { MPMPermissionsType } from './env'

export const checkRoutePermissions = ({
  path,
  userPermissions,
  permissionsByKPI,
}: {
  path: string
  userPermissions: string[]
  permissionsByKPI: MPMPermissionsType
}) => {
  const matchPathKey = permissionsByKPI[path]

  if (!matchPathKey) {
    return false
  }

  return matchPathKey.some((permission: string) =>
    userPermissions.includes(permission),
  )
}
