import { useEffect, useState } from 'react'
import { useAuth } from '@praxis/component-auth'
import { Table } from '@enterprise-ui/canvas-ui-react'
import { useUser } from 'components/user-context'
import { useSearchParams } from 'react-router-dom'
import { useMPMEnv } from 'utils/env'
import { getTableFormat } from 'utils/table'
import { Container } from 'components/container/Container'
import { usePayrollOvertime } from 'hooks/payroll/overtime'
import { UrlParams } from 'enums/url-params'
import { BasicPlaceholder } from 'components/placeholder'
import { buildDataTableObject } from 'utils/data'
import { useTranslation } from 'react-i18next'
import { messages } from '../messages'
import useRefresh from 'hooks/utils/useRefresh'
import { getLocationFilters } from 'utils/api'
import { ErrorAlert } from 'components/error-fallback'
import useCountdown from 'hooks/utils/useCountdown'

export const Overtime = () => {
  const { t } = useTranslation('payroll')
  const [params] = useSearchParams()
  const { location } = useUser()
  const env = useMPMEnv()
  const { logout, session } = useAuth()
  const currentLocation =
    params.get(UrlParams.LOCATION_ID) ?? location.shortName

  const [tableObj, setTableObj] = useState({})
  const filters = getLocationFilters(currentLocation)
  const { data, isLoading, isError, refetch, remove, error } =
    usePayrollOvertime({
      env,
      accessToken: session?.accessToken ?? '',
      location: currentLocation,
      filters,
    })
  useRefresh(refetch, remove)
  const count = useCountdown(5, logout, error as Error)

  useEffect(() => {
    const widths = [110, ...getTableFormat(data?.rows?.length ?? 20, 100)]
    if (data) {
      const obj = buildDataTableObject({
        currentData: data,
        widths,
      })
      setTableObj(obj)
    }
  }, [data])

  if (isLoading) {
    return (
      <div className="placeholder-container" data-testid="placeholder">
        <BasicPlaceholder type="text" rows={1} />
        <BasicPlaceholder type="table" rows={5} columns={3} />
      </div>
    )
  }

  if (isError || !data) {
    return <ErrorAlert error={error as Error} count={count} />
  }

  return (
    <Container>
      <p className="hc-fs-md">weekly overtime % of total hours - trend</p>
      <Table
        data={tableObj}
        scrollableWidth={true}
        name={t(messages.overtime)}
      />
    </Container>
  )
}
