export enum ApiEnvironments {
  PRODUCTION = 'production',
  STAGE = 'stage',
}

export const BaseApiUrls = {
  [ApiEnvironments.PRODUCTION]: 'https://api.target.com',
  [ApiEnvironments.STAGE]: 'https://stage-api.target.com',
}

export enum Apis {
  AGGREGATIONS = 'aggregations',
  STORE_SALES = 'store_sales',
  TEAM_MEMBERS = 'team_members',
}

type ApiEnvironmentData<DataType> = Record<ApiEnvironments, DataType>
type ApiData<DataType> = Record<Apis, DataType>

export const ApiKeys: ApiData<ApiEnvironmentData<string>> = {
  [Apis.AGGREGATIONS]: {
    [ApiEnvironments.PRODUCTION]: '8bf0373d64f0d49caa4569cba7c3c8270dfa0817',
    [ApiEnvironments.STAGE]: '95e1b34bb85f7eae3e89b2c98ac48f8ce1294406',
  },
  [Apis.STORE_SALES]: {
    [ApiEnvironments.PRODUCTION]: '8bf0373d64f0d49caa4569cba7c3c8270dfa0817',
    [ApiEnvironments.STAGE]: '95e1b34bb85f7eae3e89b2c98ac48f8ce1294406',
  },
  [Apis.TEAM_MEMBERS]: {
    [ApiEnvironments.PRODUCTION]: '8bf0373d64f0d49caa4569cba7c3c8270dfa0817',
    [ApiEnvironments.STAGE]: '95e1b34bb85f7eae3e89b2c98ac48f8ce1294406',
  },
}

export const ApiSubUrls: ApiData<string> = {
  [Apis.AGGREGATIONS]: '/mpm_aggregations/v1',
  [Apis.STORE_SALES]: '/store_sales/v2/',
  [Apis.TEAM_MEMBERS]: '/mpm_team_members/v2/users',
}
