import {
  Button,
  ButtonGroup,
  Card,
  Divider,
  Heading,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  ProfileIcon,
  StoreIcon,
  HelpIcon,
  ChatIcon,
  InfoFilledIcon,
  TextStringIcon,
} from '@enterprise-ui/icons'
import { useAuth } from '@praxis/component-auth'

import './settings.css'
import { Layout } from 'components/layout'
import {
  FloatingActionButton,
  FloatingActionButtonContainer,
} from 'components/floating-action-button'
import { useUser } from 'components/user-context'
import { useTranslation } from 'react-i18next'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from 'components/error-fallback'
import { messages } from './messages'

export const SettingsPage = () => {
  const { session } = useAuth()

  const { firstName, lanId } = session?.userInfo ?? {
    firstName: '',
    lanId: '',
  }
  const { language, setLanguage, location } = useUser()
  const { t, i18n } = useTranslation()
  const handleLanguageChange = (lang: string) => {
    setLanguage(lang)
    i18n.changeLanguage(lang)
  }

  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <ErrorFallback
          error={error}
          resetErrorBoundary={resetErrorBoundary}
          heading={t('settings')}
        />
      )}
    >
      <Layout
        heading="settings"
        isBottomNavDisplayed={false}
        className="settings-content"
      >
        <Card className="settings-info-card" data-testid="settings-info-card">
          <Heading size={4}>info</Heading>
          <Divider className="settings-info-divider" />
          <div className="settings-info-content-container">
            <span className="settings-info-label hc-fs-sm">
              <EnterpriseIcon icon={ProfileIcon} className="settings-icon" />
              {t(messages.you)}
            </span>
            <span className="hc-fs-sm">{`${firstName} (${lanId})`}</span>
            <span className="settings-info-label hc-fs-sm">
              <EnterpriseIcon icon={StoreIcon} className="settings-icon" />
              {t(messages.location)}
            </span>
            <span className="hc-fs-sm">
              <div className="settings-location-name">{location.shortName}</div>{' '}
              <div className="settings-location-name">{location.name}</div>
            </span>
            <span className="settings-info-label hc-fs-sm">
              <EnterpriseIcon icon={InfoFilledIcon} className="settings-icon" />
              {t(messages.version)}
            </span>
            <span className="hc-fs-sm">{process.env.REACT_APP_VERSION}</span>
            <span className="settings-info-label hc-fs-sm">
              <EnterpriseIcon icon={TextStringIcon} className="settings-icon" />
              {t(messages.language)}
            </span>
            <span className="hc-fs-sm">
              <ButtonGroup>
                <Button
                  className={`ghost ${language === messages.languageOptions.en ? 'lang-active' : ''}`}
                  onClick={() => {
                    handleLanguageChange(messages.languageOptions.en)
                  }}
                >
                  {t(messages.languageOptions.EN)}
                </Button>
                <Button
                  className={`ghost ${language === messages.languageOptions.es ? 'lang-active' : ''}`}
                  onClick={() => {
                    handleLanguageChange(messages.languageOptions.es)
                  }}
                >
                  {t(messages.languageOptions.ES)}
                </Button>
              </ButtonGroup>
            </span>
          </div>
        </Card>
        <FloatingActionButtonContainer>
          <FloatingActionButton icon={HelpIcon}>
            {t(messages.help)}
          </FloatingActionButton>
          <FloatingActionButton icon={ChatIcon}>
            {t(messages.feedback)}
          </FloatingActionButton>
        </FloatingActionButtonContainer>
      </Layout>
    </ErrorBoundary>
  )
}
