import qs from 'qs'
import { MPMEnvType } from 'utils/env'

type FetchProps = {
  env: MPMEnvType
  accessToken: string
  location?: string
  dynamicSubPath?: string
  current_date?: string
  filters?: {
    [key: string]: string
  }
}

export const fetchGeneralData =
  <T>(rootPath: string) =>
  async ({
    env,
    accessToken,
    location,
    dynamicSubPath,
    current_date,
    filters,
  }: FetchProps): Promise<T> => {
    const { apiKey, baseUrl } = env
    let url = `${baseUrl}${rootPath}`
    if (dynamicSubPath) {
      url += dynamicSubPath
    }

    const parameters = {
      key: apiKey,
      ...filters,
    } as { [key: string]: string }
    if (current_date) {
      parameters.current_date = current_date
    } else if (location) {
      parameters.location = location
    }
    const queryParams = qs.stringify(parameters)
    url += `?${queryParams}`

    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'x-api-key': apiKey,
      },
    }

    const response = await fetch(url, options)
    const { status, statusText } = response
    if (status !== 200) {
      throw new Error(`[${status}] ${statusText}`)
    }
    const parsedResponse = await response.json()
    return parsedResponse
  }
